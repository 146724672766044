<template>
  <div class="flex app-marketing-promotion-editor v" style="overflow: visible;" v-loading="loading">
    <template v-if="form">
      <div class="h c no-flex" style="margin-bottom: 12px;">
        <el-button type="primary" icon="el-icon-arrow-left" @click="handleBack">返回</el-button>
        <div class="padding-0-20">
          <div class="fc-g" style="font-size: 12px;">当前活动</div>
          <div style="margin-top: 2px;">{{form.name || "-"}}</div>
        </div>
      </div>
      <el-tabs v-model="currentTab">
        <el-tab-pane label="基本信息" name="base"></el-tab-pane>

        <el-tab-pane label="促销明细" name="detail"></el-tab-pane>
      </el-tabs>

      <div class="flex scroll-able" style="margin-right: -20px; padding-right: 20px;" v-show="currentTab === 'base'">
        <el-form ref="form" :model="form" :rules="rules" label-position="top" hide-required-asterisk :class="{layout_disabled: !editable}">
          <el-form-item label="流水号" style="width: 400px;" v-if="form.code">
            <el-input readonly :value="form.code" />
          </el-form-item>
          <div class="h sb wrap" style="width: 840px;">
            <el-form-item label="活动名称" prop="name" style="width: 400px;">
              <el-input v-model="form.name" :maxlength="70" placeholder="请输入活动名称" />
            </el-form-item>
            <el-form-item label="活动开始时间" prop="beginTime" style="width: 200px;">
              <el-date-picker style="width: 100%;" v-model="form.beginTime" type="datetime" format="yyyy/MM/dd HH:mm:ss" value-format="timestamp" default-time="00:00:00" />
            </el-form-item>
            <el-form-item label="活动结束时间" prop="endTime" style="width: 200px;">
              <el-date-picker style="width: 100%;" v-model="form.endTime" type="datetime" format="yyyy/MM/dd HH:mm:ss" value-format="timestamp" default-time="23:59:59" />
            </el-form-item>

            <el-form-item label="活动图片" style="width: 200px;">
              <file-uploader :entity-id="form.fileId" folder="promotion/cover_img" :thumbnail-size="140" :ratio="2" :readonly="!editable"></file-uploader>
            </el-form-item>

            <el-form-item label="活动介绍" prop="info" style="width: 620px;">
              <el-input type="textarea" v-model="form.info" :maxlength="1000" placeholder="请输入活动介绍、说明等" :rows="3" resize="none" />
            </el-form-item>

            <el-form-item label="促销类型" prop="proType" style="width: 400px;">
              <radio-group v-model="form.proType" :options="types" @change="handleClearSub('proType', arguments[0], arguments[1])" />
            </el-form-item>
            <el-form-item label="促销方式" prop="method" style="width: 420px;">
              <radio-group v-model="form.method" :options="methods" @change="handleClearSub('method', arguments[0], arguments[1])" />
            </el-form-item>

            <el-form-item label="促销条件" prop="proCondition" style="width: 220px;">
              <radio-group v-model="form.proCondition" :options="conditions" />
            </el-form-item>

            <div class="h sb" style="width: 420px;">
              <el-form-item prop="quantityLadder" style="width: 180px;">
                <div slot="label" class="h c">
                  <b>阶梯&nbsp;</b>
                  <el-tooltip placement="top">
                    <div slot="content" style="width: 240px; line-height: 1.5;">
                      同一件/组商品、分类，在满足不同的条件时，可获得的优惠力度不同。
                      <br />例如：A商品在购买1件时打9折，购买2件时打8折。
                    </div>
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </div>
                <el-checkbox v-model="form.quantityLadder" @change="handleClearSub('quantityLadder', $event)">启用</el-checkbox>
              </el-form-item>
              <el-form-item prop="giftAddUp" style="width: 220px;">
                <div slot="label" class="h c">
                  <b>累计叠加&nbsp;</b>
                  <el-tooltip placement="top">
                    <div slot="content" style="width: 240px; line-height: 1.5;">
                      同一件/组商品、分类，每满足同一条件时，可获得的赠品进行累计叠加。
                      <br />例如：A商品设置买2送1，则买2个或3个A商品时获得1个赠品，买4个A商品则可获得2个赠品，以此类推。
                    </div>
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </div>
                <el-checkbox v-model="form.giftAddUp" :disabled="form.method !== 'gift'">启用</el-checkbox>
              </el-form-item>
            </div>

            <el-form-item prop="mutex" style="width: 200px;">
              <div slot="label" class="h c">
                <b>与整单优惠活动互斥&nbsp;</b>
                <el-tooltip placement="top">
                  <div slot="content" style="width: 240px; line-height: 1.5;">当参加此活动后，参加此活动的商品是否还可以参与整单优惠活动；启用互斥后则不可再参加。</div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
              <el-checkbox v-model="form.mutex">启用</el-checkbox>
            </el-form-item>

            <el-form-item label="活动参与次数限制" prop="maxTimes" style="width: 400px;">
              <div class="h c">
                <el-input-number v-model.number="form.allMaxTimes" :min="0" :max="99999999" controls-position="right" @change="$checkNaN(form, 'allMaxTimes', 0)" style="width: 150px;" />
                <div class="padding-0-10 fc-g">0表示不限制次数</div>
              </div>
            </el-form-item>

            <el-form-item label="同一客户参与次数限制" prop="maxTimes" style="width: 420px;">
              <div class="h c">
                <el-input-number v-model.number="form.maxTimes" :min="0" :max="999999" @change="$checkNaN(form, 'maxTimes', 0)" controls-position="right" style="width: 150px;" />
                <div class="padding-0-10 fc-g">0表示不限制参与次数</div>
              </div>
            </el-form-item>

            <div class="h" style="width: 840px;">
              <el-form-item label="促销维度" prop="targetType" style="width: 150px; margin-right: 10px;">
                <quick-select v-model="form.targetType" :options="targetTypes" display-field="label" :before-change="handleTargetTypeBeforeChange" @change="handleTargetTypeChange" style="width: 100%;" />
              </el-form-item>
              <el-form-item label="　" key="_target_type_1" class="flex" v-if="form.targetType=='distributor'">
                <datagrid-picker v-model="form.target" multiple submit url="api/distributor" value-field="enterpriseId" query-value-field="ids" :query-define="distributorQueryDefine" :show-index="false" placeholder="请选择经销商" style="width: 100%;">
                  <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
                    <el-input v-if="$erp.enable()" v-model="distributorQueryDefine.erpId" :validate-event="false" clearable placeholder="ERP编号搜索" @clear="scope.query" @keyup.enter.native="toQuery" style="width: 130px;" />
                    <el-input :maxlength="20" v-model="distributorQueryDefine.name" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入经销商名称进行搜索" style="width: 240px;" />
                    <el-button type="success" icon="el-icon-search" @click.stop="scope.query" />
                  </div>
                  <el-table-column prop="erpId" label="ERP编码" min-width="130" v-if="$erp.enable()" />
                  <el-table-column prop="name" label="经销商" min-width="160" />
                  <el-table-column label="所在地区" min-width="200" :formatter="v=>{return v.provinceName+'/'+v.cityName + '/' + v.districtName}" />
                </datagrid-picker>
              </el-form-item>
              <el-form-item label="　" key="_target_type_2" class="flex" v-else-if="form.targetType=='distributorType'">
                <datagrid-picker v-model="form.target" multiple submit url="api/distributorLeve" query-value-field="ids" :show-index="false" placeholder="请选择经销商类型" style="width: 100%;">
                  <el-table-column prop="name" label="经销商类型" min-width="160" />
                </datagrid-picker>
              </el-form-item>
              <el-form-item label="　" key="_target_type_3" class="flex" v-else-if="form.targetType=='distributorGroup'">
                <datagrid-picker v-model="form.target" multiple submit url="api/distributorGroup" query-value-field="ids" :query-define="distributorGroupQueryDefine" :show-index="false" placeholder="请选择经销商组" style="width: 100%;">
                  <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
                    <el-input :maxlength="20" v-model="distributorGroupQueryDefine.name" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入经销商组名称进行搜索" style="width: 240px;" />
                    <el-button type="success" icon="el-icon-search" @click.stop="scope.query" />
                  </div>
                  <el-table-column prop="name" label="经销商组名称" min-width="160" />
                </datagrid-picker>
              </el-form-item>
              <el-form-item label="　" key="_target_type_4" class="flex" v-else-if="form.targetType=='distributorArea'">
                <quick-cascader v-model="form.target" url="api/area" multiple flat placeholder="请选择区域" filterable style="width: 100%;" />
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>

      <div class="h c no-flex" style="margin-bottom: 10px;" v-if="editable" v-show="currentTab === 'detail'">
        <el-button type="primary" @click="handleAddSub">添加促销项目</el-button>
        <el-button type="danger" @click="handleClearSub(null)">清空所有项目</el-button>
      </div>
      <div class="flex scroll-able" style="margin-right: -20px; padding-right: 20px;" v-show="currentTab === 'detail'">
        <template v-if="form._subs && form._subs.length">
          <template v-for="(sub, inx) in form._subs">
            <el-card :header="`促销项目${inx + 1}`" shadow="hover" :key="sub._id" style="margin-bottom: 20px;">
              <el-button-group style="margin-bottom: 10px;" v-if="editable">
                <el-button size="mini" plain @click="handleAddItem(sub)" :disabled="form.quantityLadder && sub._items.length > 0">添加条件</el-button>
                <el-button size="mini" plain @click="handleClearItem(sub)">清空条件</el-button>
                <el-button size="mini" plain @click="handleRemoveSub(sub)">删除促销项目</el-button>
              </el-button-group>

              <table cellspacing="0" cellpadding="0" border="0">
                <thead>
                  <tr>
                    <th style="width: 100px;">
                      {{form.quantityLadder ? "阶梯" : "条件"}}&nbsp;
                      <el-tooltip placement="bottom">
                        <div class="lh-150" style="max-width: 320px;" slot="content">
                          <template v-if="form.quantityLadder">
                            <b>阶梯说明：</b>
                            <br />相同商品或分类下，达到不同条件时，可设置不同的优惠力度，但只能获得其中满足的条件最高的阶梯的优惠，比如：商品A有购买1、5、10件三个阶梯，当购买数量在5-9个时，则获得5这个阶梯的优惠，购买数量达到10及以上时，获得10这个阶梯的优惠。
                          </template>
                          <template v-else>
                            <b>条件说明</b>
                            <br />每个促销项目可以设置多个条件，当达到所有条件时才可以获得优惠。
                          </template>
                        </div>
                        <i class="fc-g el-icon-question"></i>
                      </el-tooltip>
                    </th>
                    <th style="width: 160px;">{{form.proCondition ? "满额" : "满件"}}</th>
                    <th>
                      {{form.proType === 'group' ? '可参与活动商品' : '可参与活动分类'}}
                      <el-tooltip placement="bottom">
                        <div class="lh-150" style="max-width: 320px;" slot="content">
                          <b>可参与活动商品说明：</b>
                          <br />每个条件/阶梯可设置多个商品或分类，当这些商品或分类中有一个或多个达到满件/满额，则当前条件成立或享受当前阶梯的优惠。
                        </div>
                        <i class="fc-g el-icon-question"></i>
                      </el-tooltip>
                    </th>
                    <template v-if="form.method === 'discount'">
                      <th style="width: 160px;">打折</th>
                      <th style="width: 100px;">折扣率</th>
                    </template>
                    <th style="width: 160px;" v-else-if="form.method === 'save'">单价减价</th>
                    <th style="width: 160px;" v-else-if="form.method === 'price'">一口价（单价）</th>
                    <th v-else-if="form.method === 'gift'" style="width: 720px;">
                      赠品详情&nbsp;
                      <el-tooltip placement="bottom">
                        <div class="lh-150" style="max-width: 320px;" slot="content">
                          <b>赠品说明：</b>
                          <br />每个促销项目可设置多个赠品目录（如果开启阶梯，则每个阶梯可单独设置赠品目录），每个赠品目录下可包含多种赠品。
                          <br />当满足参与条件时，则可以从每个赠品目录中选取任意一种赠品。
                        </div>
                        <i class="fc-g el-icon-question"></i>
                      </el-tooltip>
                    </th>
                    <th style="width: 100px;" v-if="editable">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="sub._items && sub._items.length">
                    <template v-for="(g, gi) in sub._items">
                      <template v-for="(l, li) in g._ladders">
                        <template v-for="(t, ti) in l.targets">
                          <tr :key="t._id">
                            <td class="ta-c" v-if="form.quantityLadder && ti === 0" :rowspan="getRowSpanByLadder(l)">
                              <b>阶梯{{li + 1}}</b>
                            </td>
                            <td class="ta-c" v-else-if="!form.quantityLadder && ti === 0" :rowspan="getRowSpanByGroup(g)">
                              <b>条件{{gi + 1}}</b>
                            </td>
                            <template v-if="ti === 0">
                              <td key="con_price" :rowspan="l.targets.length || 1" v-if="form.proCondition">
                                <price-input v-model="l.reachCondition" :min="0.01" style="width: 100%;" v-if="editable" />
                                <span v-else>{{$price(l.reachCondition)}}</span>
                              </td>
                              <td key="con_count" :rowspan="l.targets.length || 1" v-else>
                                <el-input-number v-model="l.reachCondition" :min="1" :max="9999999" :step="1" :precision="0" controls-position="right" @change="$checkNaN(l, 'reachCondition', 1)" style="width: 100%;" v-if="editable" />
                                <span v-else>{{l.reachCondition}}</span>
                              </td>
                            </template>
                            <template v-if="t.targetId">
                              <td>
                                <div class="h c">
                                  <div class="flex lh-150" v-if="form.proType === 'group'">
                                    <span>{{t.name}}</span>
                                    <br />
                                    <span class="fc-g">{{$goodsSpecConvert(t.specs)}}</span>
                                  </div>
                                  <div class="flex lh-150" v-else-if="form.proType === 'category'">{{t.name}}</div>
                                  <div class="row-commands no-flex" v-if="editable">
                                    <el-button type="text" size="mini" @click="handleRemoveTarget(sub, g, t)">删除</el-button>
                                  </div>
                                </div>
                              </td>
                            </template>
                            <template v-else>
                              <td v-if="editable">
                                <div class="row-commands">
                                  <el-button type="text" size="mini" @click="handleAddGoods(g)" v-if="form.proType === 'group'">添加商品</el-button>
                                  <el-button type="text" size="mini" @click="handleAddCategory(g)" v-else-if="form.proType === 'category'">添加分类</el-button>
                                </div>
                              </td>
                              <td v-else></td>
                            </template>
                            <template v-if="form.method === 'discount'">
                              <template v-if="t.targetId">
                                <td class="ta-c">
                                  <percent-input v-model="t.discount" :precision="1" :max="10" :base="1" symbol="折" style="width: 100%;" v-if="editable" />
                                  <span v-else>{{Math.round(t.discount * 1000) / 100}}折</span>
                                </td>
                                <td class="ta-c">{{Math.round((1 - t.discount) * 10000) / 100}}%</td>
                              </template>
                              <template v-else>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </template>
                            </template>
                            <template v-else-if="form.method === 'save'">
                              <template v-if="t.targetId">
                                <td class="ta-c">
                                  <price-input v-model="t.saveAmount" :min="0" style="width: 100%;" v-if="editable" />
                                  <span v-else>{{$price(t.saveAmount)}}</span>
                                </td>
                              </template>
                              <template v-else>
                                <td>&nbsp;</td>
                              </template>
                            </template>
                            <template v-else-if="form.method === 'price'">
                              <template v-if="t.targetId">
                                <td class="ta-c">
                                  <price-input v-model="t.price" :min="0" style="width: 100%;" v-if="editable" />
                                  <span v-else>{{$price(t.price)}}</span>
                                </td>
                              </template>
                              <template v-else>
                                <td>&nbsp;</td>
                              </template>
                            </template>
                            <template v-else-if="form.method === 'gift'">
                              <template v-if="form.quantityLadder">
                                <td v-if="ti === 0" :rowspan="getRowSpanByLadder(l)">
                                  <template v-if="l.giftRules && l.giftRules.length">
                                    <table cellpadding="0" cellspacing="0" style="width: 100%">
                                      <thead>
                                        <tr>
                                          <th>赠品</th>
                                          <th style="width: 160px;">单价</th>
                                          <th style="width: 120px;">数量</th>
                                          <th style="width: 50px;" v-if="editable"></th>
                                        </tr>
                                        <template v-for="(r, ri) in l.giftRules">
                                          <tr class="bc-l">
                                            <td :colspan="editable ? 4 : 3">
                                              <div class="h c">
                                                <div class="flex">赠品目录{{ri + 1}}</div>
                                                <div class="row-commands" v-if="editable">
                                                  <el-button type="text" size="mini" @click="handleAddGift(l, r)">添加赠品</el-button>
                                                  <el-button type="text" size="mini" class="danger" @click="handleRemoveGiftRule(l, r)">删除目录</el-button>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <template v-if="r.goodsList && r.goodsList.length">
                                            <tr v-for="g in r.goodsList" :key="g._id">
                                              <td>
                                                <span>{{g.name}}</span>
                                                <br />
                                                <span class="fc-g">{{$goodsSpecConvert(g.specs)}}</span>
                                              </td>
                                              <td>
                                                <price-input v-model="g.price" :min="0" style="width: 100%;" v-if="editable" />
                                                <span v-else>{{$price(g.price)}}</span>
                                              </td>
                                              <td>
                                                <el-input-number v-model="g.quantity" :min="0" :max="9999999" :step="1" :precision="0" controls-position="right" style="width: 100%;" v-if="editable" />
                                                <span v-else>{{g.quantity}}</span>
                                              </td>
                                              <td v-if="editable">
                                                <div class="row-commands">
                                                  <el-button type="text" size="mini" @click="handleRemoveGift(r, g)">删除</el-button>
                                                </div>
                                              </td>
                                            </tr>
                                          </template>
                                          <template v-else>
                                            <tr>
                                              <td :colspan="editable ? 4 : 3" class="fc-g ta-c">当前目录未选择赠品</td>
                                            </tr>
                                          </template>
                                        </template>
                                      </thead>
                                    </table>
                                    <div class="row-commands" style="margin-top: 10px;">
                                      <el-button type="text" size="mini" @click="handleAddGiftRule(l)">添加赠品目录</el-button>
                                    </div>
                                  </template>
                                  <div class="v c" v-else>
                                    <div class="fc-g padding-05">还没有设置赠品哦</div>
                                    <el-button type="info" icon="el-icon-plus" @click="handleAddGiftRule(l)">添加赠品目录</el-button>
                                  </div>
                                </td>
                              </template>
                              <template v-else>
                                <td v-if="gi === 0 && li === 0 && ti === 0" :rowspan="getRowSpanByForm(l)">
                                  <template v-if="sub.giftRules && sub.giftRules.length">
                                    <table cellpadding="0" cellspacing="0" style="width: 100%">
                                      <thead>
                                        <tr>
                                          <th>赠品</th>
                                          <th style="width: 160px;">单价</th>
                                          <th style="width: 120px;">数量</th>
                                          <th style="width: 50px;" v-if="editable"></th>
                                        </tr>
                                        <template v-for="(r, ri) in sub.giftRules">
                                          <tr class="bc-l">
                                            <td :colspan="editable ? 4 : 3">
                                              <div class="h c">
                                                <div class="flex">赠品目录{{ri + 1}}</div>
                                                <div class="row-commands" v-if="editable">
                                                  <el-button type="text" size="mini" @click="handleAddGift(sub, r)">添加赠品</el-button>
                                                  <el-button type="text" size="mini" class="danger" @click="handleRemoveGiftRule(sub, r)">删除目录</el-button>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <template v-if="r.goodsList && r.goodsList.length">
                                            <tr v-for="g in r.goodsList" :key="g._id">
                                              <td>
                                                <span>{{g.name}}</span>
                                                <br />
                                                <span class="fc-g">{{$goodsSpecConvert(g.specs)}}</span>
                                              </td>
                                              <td>
                                                <price-input v-model="g.price" :min="0" style="width: 100%;" v-if="editable" />
                                                <span v-else>{{$price(g.price)}}</span>
                                              </td>
                                              <td>
                                                <el-input-number v-model="g.quantity" :min="0" :max="9999999" :step="1" :precision="0" controls-position="right" style="width: 100%;" v-if="editable" />
                                                <span v-else>{{g.quantity}}</span>
                                              </td>
                                              <td v-if="editable">
                                                <div class="row-commands">
                                                  <el-button type="text" size="mini" @click="handleRemoveGift(r, g)">删除</el-button>
                                                </div>
                                              </td>
                                            </tr>
                                          </template>
                                          <template v-else>
                                            <tr>
                                              <td :colspan="editable ? 4 : 3" class="fc-g ta-c">当前目录未选择赠品</td>
                                            </tr>
                                          </template>
                                        </template>
                                      </thead>
                                    </table>
                                    <div class="row-commands" style="margin-top: 10px;" v-if="editable">
                                      <el-button type="text" size="mini" @click="handleAddGiftRule(sub)">添加赠品目录</el-button>
                                    </div>
                                  </template>
                                  <div class="v c" v-else>
                                    <div class="fc-g padding-05">还没有设置赠品哦</div>
                                    <el-button type="info" icon="el-icon-plus" @click="handleAddGiftRule(sub)" v-if="editable">添加赠品目录</el-button>
                                  </div>
                                </td>
                              </template>
                            </template>

                            <td key="con_command" :rowspan="l.targets.length || 1" v-if="editable && ti === 0">
                              <div class="row-commands v">
                                <el-button type="text" size="mini" @click="handleAddLadder(g, l)" v-if="form.quantityLadder">添加阶梯</el-button>
                                <el-button type="text" size="mini" @click="handleRemoveLadder(sub, g, l)">删除</el-button>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </template>
                    </template>
                  </template>
                  <tr v-else>
                    <td :colspan="4 + (form.method === 'discount' ? 1 : 0) + (editable ? 1 : 0)" class="ta-c fc-g" style="padding: 50px 0;">还没有促销明细</td>
                  </tr>
                </tbody>
              </table>
            </el-card>
          </template>
        </template>
        <div class="fc-g padding-20-0" v-else>当前活动还没有促销项目，请添加。</div>
      </div>

      <div class="gap-1x no-flex">
        <el-button type="primary" @click="doSave" :disabled="operaing" :loading="saving" v-if="editable">保存商品促销活动</el-button>
        <template v-if="form && form.id">
          <el-button type="danger" @click="doStop" :disabled="saving" :loading="operaing" v-if="form.status === 'start' && checkPermission(['MARKET_MANAGE','MARKET_MANAGE_START'])">反审商品促销活动</el-button>
          <el-button type="success" @click="doStart" :disabled="saving" :loading="operaing" v-else-if="checkPermission(['MARKET_MANAGE','MARKET_MANAGE_START'])">审核商品促销活动</el-button>
        </template>
      </div>
    </template>

    <sku-selector ref="goodsSel" :title="selMode === 'gift' ? '选择赠品' : '选择商品'" request-url="api/goods/sku" :request-params="{onShelf: true}" multiple :exclude-keys="excludeSkusByCondition" :show-button="false" :show-details="false" @submit="handleGoodsSelect" />

    <el-dialog :visible.sync="category.show" title="选择分类" append-to-body :close-on-click-modal="false" width="640px;">
      <el-tree ref="categoryList" :data="category.store" node-key="id" show-checkbox check-on-click-node :expand-on-click-node="false" default-expand-all :props="category.props" v-if="category.show">
        <div class="flex h c" slot-scope="{ data }">
          <div class="flex">{{ data.name }}</div>
          <div class="fc-g" style="width: 320px;">{{data.info}}</div>
        </div>
      </el-tree>
      <div slot="footer">
        <el-button type="text" @click="category.show = false">取消</el-button>
        <el-button type="primary" @click="handleCategorySelect">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { promotion } from "@/api/marketing";
import request from "@/utils/request";
import skuSelector from "@/views/assembly/skuSelect";
import { getCategory } from "@/api/category";
import checkPermission from "@/utils/permission";

export default {
  components: { skuSelector },
  computed: {
    ...mapGetters(["user"]),
  },
  props: {
    id: String | Number,
    isCopy: Boolean,
    readonly: Boolean,
  },
  data() {
    return {
      form: null,
      saving: false,
      operaing: false,
      maxSubIndex: 0,
      dis: {
        queryDefine: {},
      },
      methods: [
        { id: "discount", label: "打折" },
        { id: "save", label: "减价" },
        { id: "price", label: "一口价" },
        { id: "gift", label: " 赠商品" },
      ],
      types: [
        { id: "group", label: "商品促销" },
        { id: "category", label: "分类促销" },
      ],
      targetTypes: [
        { id: "all", label: "全部" },
        { id: "distributor", label: "经销商" },
        { id: "distributorType", label: "经销商类型" },
        { id: "distributorGroup", label: "经销商组" },
        { id: "distributorArea", label: "区域" },
      ],
      conditions: [
        { id: false, label: "满件" },
        { id: true, label: "满额" },
      ],
      thresholds: [
        { id: true, label: "整单" },
        { id: false, label: "活动商品" },
      ],
      calcTypes: [
        { id: true, label: "赠送金额" },
        { id: false, label: "赠品数量" },
      ],
      categoryRelations: [
        { id: true, label: "优先单品促" },
        { id: false, label: "以单品促为准" },
      ],
      distributorQueryDefine: {
        name: null,
        erpId: null,
      },
      distributorGroupQueryDefine: {
        name: "",
      },
      currentTab: "base",
      loading: false,
      defaultTime: ["00:00:00", "23:59:59"],
      currentItem: null,
      currentLadder: null,
      currentGiftRule: null,
      selMode: null,
      category: {
        show: false,
        store: [],
        includes: [],
        props: {
          disabled: (data) => {
            return this.category.includes.indexOf(data.id) >= 0;
          },
        },
      },
      rules: {
        name: [
          { required: "true", message: "活动名称为必填项", trigger: "blur" },
        ],
        beginTime: [
          {
            required: "true",
            message: "活动开始时间为必填项",
            trigger: "blur",
          },
        ],
        endTime: [
          {
            required: "true",
            message: "活动结束时间为必填项",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    editable() {
      return this.form && this.form.status === "init" && !this.readonly;
    },
    excludeSkusByCondition() {
      if (this.selMode === "goods") {
        let os = [];
        (this.currentItem._ladders || []).forEach((ladder) => {
          (ladder.targets || []).forEach((o) => {
            if (o.type === "sku" && o.targetId) os.push(o.targetId);
          });
        });
        return os;
      } else if (this.selMode === "gift" && this.currentLadder) {
        let os = [];
        (this.currentLadder.giftRules || []).forEach((r) => {
          (r.goodsList || []).forEach((o) => {
            os.push(o.goodsId);
          });
        });
        return os;
      }
      return null;
    },
    detailsColumnWidth() {
      let w = 100;
      if (this.form) {
        if (this.form.method === "discount") w = 640;
        else if (this.form.method === "save") w = 480;
        else if (this.form.method === "price") w = 480;
        else if (this.form.method === "gift")
          w = this.form.quantityLadder ? 960 : 290;
      }
      return String(w);
    },
  },
  methods: {
    checkPermission,
    handleBack() {
      this.$emit("cancel");
    },

    calcDisabledCategories(current) {
      let arr = [];
      this.form._items.forEach((item) => {
        if (item.groupId !== current.groupId) {
          arr = arr.concat(item._categories);
        }
      });
      return arr;
    },

    getRowSpanByForm() {
      let row = 0;
      (this.form._items || []).forEach((g) => {
        row += this.getRowSpanByGroup(g);
      });
      return row;
    },

    getRowSpanByGroup(group) {
      let rows = 0;
      (group._ladders || []).forEach((l) => {
        rows += l.targets.length;
      });
      return rows;
    },
    getRowSpanByLadder(ladder) {
      return ladder.targets.length;
    },

    handleAddSub() {
      let o = {
        _id: this.$uuid(),
        displayNo: ++this.maxSubIndex,
        giftRules: [],
        _items: [],
        _maxGroupIndex: 0,
        _maxGiftRuleIndex: 0,
      };
      this.form._subs.push(o);
      this.handleAddItem(o);
    },
    handleRemoveSub(sub, notice = true) {
      let inx = this.form._subs.indexOf(sub);
      if (inx >= 0) {
        if (notice) {
          this.$confirm(`确定要删除该活动项目吗？`, "操作确认", {
            type: "warning",
          }).then((_) => {
            this.form._subs.splice(inx, 1);
          });
        } else {
          this.form._subs.splice(inx, 1);
        }
      }
    },
    handleClearSub(field, val, oldVal = null) {
      if (field === "method" && val !== "gift") {
        this.form.giftAddUp = false;
      }
      if (this.form._subs && this.form._subs.length) {
        let tts = {
          proType: "促销类型",
          method: "促销方式",
          quantityLadder: "阶梯",
        };
        this.$confirm(
          field
            ? `更改【${tts[field]}】设置将清空已经设置的活动项目，确定要更改吗？`
            : `确定要清空已经设置的活动项目吗？`,
          "操作确认",
          {
            type: "warning",
          }
        )
          .then((_) => {
            this.form._subs = [];
            this.maxSubIndex = 0;
          })
          .catch((_) => {
            this.form[field] = oldVal === null ? !val : oldVal;
          });
      }
    },

    handleAddItem(sub) {
      let o = {
        groupId: this.$uuid(),
        displayNo: ++sub._maxGroupIndex,
        _maxLadderIndex: 0,
        _ladders: [],
      };
      sub._items.push(o);
      this.handleAddLadder(o);
    },
    handleRemoveItem(sub, item) {
      let inx = sub._items.indexOf(item);
      if (
        inx === 0 &&
        this.form.method === "gift" &&
        !this.form.quantityLadder &&
        sub._items.length > 1
      ) {
        sub._items[1]._maxGiftRuleIndex = sub._items[0]._maxGiftRuleIndex;
        sub._items[1].giftRules = sub._items[0].giftRules;
      }
      if (inx >= 0) {
        sub._items.splice(inx, 1);
        // if (!sub._items.length) this.handleRemoveSub(sub, false);
      }
    },
    handleClearItem(sub) {
      this.$confirm(
        `确定要清空当前活动项目已经设置的活动内容吗？`,
        "操作确认",
        {
          type: "warning",
        }
      ).then((_) => {
        sub._items = [];
        sub._maxGroupIndex = 0;
        sub._maxGiftRuleIndex = 0;
      });
    },

    handleAddLadder(item, source = null) {
      item._maxLadderIndex++;
      let ladder = null;
      if (source) {
        ladder = Object.assign(JSON.parse(JSON.stringify(source)), {
          _id: this.$uuid(),
          ladderId: this.$uuid(),
          displayNo: item._maxLadderIndex,
          giftRules: [],
          _maxTargetIndex: 0,
          _maxGiftRuleIndex: 0,
        });
        ladder.targets.forEach((t) => {
          t._id = this.$uuid();
          t.ladderId = ladder.ladderId;
        });
      } else {
        ladder = {
          _id: this.$uuid(),
          ladderId: this.$uuid(),
          displayNo: item._maxLadderIndex,
          groupId: item.groupId,
          reachCondition: this.form.proCondition ? 1000000 : 1,
          targets: [
            {
              _id: this.$uuid(),
              targetId: null,
            },
          ],
          giftRules: [],
          _maxTargetIndex: 0,
          _maxGiftRuleIndex: 0,
        };
      }
      item._ladders.push(ladder);

      if (!source) {
        this.form.proType === "group"
          ? this.handleAddGoods(item)
          : this.handleAddCategory(item);
      } else if (this.form.method === "gift" && this.form.quantityLadder) {
        this.handleAddGiftRule(ladder);
      }
    },
    handleRemoveLadder(sub, item, ladder) {
      let inx = item._ladders.indexOf(ladder);
      if (inx >= 0) item._ladders.splice(inx, 1);
      if (item._ladders.length === 0) {
        this.handleRemoveItem(sub, item);
      }
    },

    handleAddGiftRule(ladder) {
      ladder._maxGiftRuleIndex++;
      ladder.giftRules.push({
        _id: this.$uuid(),
        _maxGiftIndex: 0,
        displayNo: ladder._maxGiftRuleIndex,
        groupId: ladder.groupId,
        ladderId: ladder.ladderId,
        goodsList: [],
      });
    },
    handleRemoveGiftRule(ladder, rule) {
      let inx = ladder.giftRules.indexOf(rule);
      if (inx >= 0) ladder.giftRules.splice(inx, 1);
    },

    handleAddGoods(item) {
      this.selMode = "goods";
      this.currentItem = item;
      this.$refs.goodsSel && this.$refs.goodsSel.open();
    },
    handleAddCategory(item) {
      let ins = [];
      (item._ladders || []).forEach((l) => {
        ins = ins.concat(
          (l.targets || []).filter((t) => !!t.targetId).map((t) => t.targetId)
        );
      });
      this.currentItem = item;
      this.category.includes = ins;
      this.category.show = true;
    },
    handleRemoveTarget(sub, item, target) {
      for (let i = 0; i < item._ladders.length; i++) {
        let l = item._ladders[i];
        let inx = l.targets.findIndex((t) => t.targetId === target.targetId);
        if (inx >= 0) l.targets.splice(inx, 1);
        if (l.targets.length === 1) {
          this.handleRemoveLadder(sub, item, l);
          i--;
        }
      }
    },

    handleAddGift(ladder, rule) {
      this.selMode = "gift";
      this.currentLadder = ladder;
      this.currentGiftRule = rule;
      this.$refs.goodsSel && this.$refs.goodsSel.open();
    },
    handleRemoveGift(rule, gift) {
      let inx = rule.goodsList.indexOf(gift);
      if (inx >= 0) rule.goodsList.splice(inx, 1);
    },

    handleGoodsSelect(res) {
      if (res && res.length) {
        if (this.selMode === "goods" && this.currentItem) {
          res.forEach((g) => {
            let o = {
              type: "sku",
              targetId: g.id,
              specs: g.specs,
              spuId: g.spuId,
              name: g.name,
              code: g.code,
              erpCode: g.erpCode,
              groupId: this.currentItem.groupId,
              discount: 1,
              saveAmount: 0,
              price: 0,
            };

            this.currentItem._ladders.forEach((l) => {
              l._maxTargetIndex++;
              l.targets.splice(
                l.targets.length - 1,
                0,
                Object.assign({}, o, {
                  _id: this.$uuid(),
                  displayNo: l._maxTargetIndex,
                  ladderId: l.ladderId,
                })
              );
            });
          });
        } else if (this.selMode === "gift" && this.currentGiftRule) {
          res.forEach((g) => {
            this.currentGiftRule._maxGiftIndex++;
            this.currentGiftRule.goodsList.push({
              _id: this.$uuid(),
              displayNo: this.currentGiftRule._maxGiftIndex,
              goodsId: g.id,
              specs: g.specs,
              name: g.name,
              code: g.code,
              erpCode: g.erpCode,
              price: 0,
              quantity: 1,
            });
          });
        }
      }
    },
    handleCategorySelect() {
      if (this.$refs.categoryList && this.currentItem) {
        let checkedItems = this.$refs.categoryList.getCheckedNodes(true);
        if (checkedItems && checkedItems.length) {
          checkedItems.forEach((g) => {
            let o = {
              type: "category",
              targetId: g.id,
              name: g.name,
              groupId: this.currentItem.groupId,
              discount: 1,
              saveAmount: 0,
              price: 0,
            };

            this.currentItem._ladders.forEach((l) => {
              l._maxTargetIndex++;
              l.targets.splice(
                l.targets.length - 1,
                0,
                Object.assign({}, o, {
                  _id: this.$uuid(),
                  displayNo: l._maxTargetIndex,
                  ladderId: l.ladderId,
                })
              );
            });
          });
          this.category.show = false;
        } else {
          this.$message.warning("请选择至少一个分类");
        }
      }
    },

    handleTargetTypeBeforeChange() {
      return this.$confirm(
        "切换促销纬度将会重置所选的维度中的数据,确定切换吗？",
        "提示",
        {
          type: "warning",
        }
      );
    },
    handleTargetTypeChange() {
      this.form.target = [];
    },

    convertForm() {
      let form = null;
      if (this.form._subs && this.form._subs.length) {
        form = JSON.parse(JSON.stringify(this.form));

        let err = null;

        for (let si = 0, sl = form._subs.length; si < sl; si++) {
          let sub = form._subs[si],
            rules = [],
            targets = [],
            gifts = [];

          if (!sub._items || !sub._items.length) {
            err = "每个活动项目至少要有一个条件或阶梯";
            break;
          }

          for (let i = 0, l = sub._items.length; i < l; i++) {
            let item = sub._items[i];
            for (let x = 0, xl = item._ladders.length; x < xl; x++) {
              let ladder = item._ladders[x];
              let ts = (ladder.targets || []).filter((t) => !!t.targetId);
              if (ts && ts.length) {
                if (form.method === "gift" && form.quantityLadder) {
                  let rs = (ladder.giftRules || []).filter(
                    (t) => !!t.goodsList.length
                  );
                  if (rs && rs.length) {
                    rs.groupId = ladder.groupId;
                    gifts = gifts.concat(rs);
                  } else {
                    err = "当前促销明细中有未设置“赠品”的阶梯，请检查。";
                    break;
                  }
                }
                rules.push({
                  groupId: ladder.groupId,
                  ladderId: ladder.ladderId,
                  reachCondition: ladder.reachCondition,
                  displayNo: ladder.displayNo,
                });
                targets = targets.concat(ts);
              } else {
                err =
                  "当前促销明细中有未设置“可参与活动商品”的条件或阶梯，请检查。";
                break;
              }
            }
            if (err) break;
          }

          if (!err && form.method === "gift" && !form.quantityLadder) {
            let ladder = sub._items[0]._ladders[0];
            let ts = (sub.giftRules || []).filter((t) => !!t.goodsList.length);
            if (ts && ts.length) {
              ts.forEach((r) => {
                r.groupId = ladder.groupId;
                r.ladderId = ladder.ladderId;
              });
              gifts = gifts.concat(ts);
            } else {
              err = "当前促销明细中未设置“赠品”，请检查。";
              break;
            }
          }

          sub.rules = rules;
          sub.rulesGoods = targets;
          sub.giftRules = gifts;
          delete sub._items;
        }

        if (err) {
          this.$message.error(err);
          this.currentTab = "detail";
          form = null;
        } else {
          form.target = form.target.join(",");
          form.sublist = form._subs;
          delete form._subs;
        }

        //
        // let _rules = [],
        //   _rulesGoods = [],
        //   _giftRules = [];
        // let err = null;

        // for (let a = 0, al = this.form._items.length; a < al; a++) {
        //   let item = this.form._items[a];
        //   if (
        //     form.proType === "group" &&
        //     (!item.rulesGoods || !item.rulesGoods.length)
        //   ) {
        //     err = "条件" + (a + 1) + "还没有选择商品";
        //     break;
        //   } else if (
        //     form.proType === "category" &&
        //     (!item._categories || !item._categories.length)
        //   ) {
        //     err = "条件" + (a + 1) + "还没有选择分类";
        //     break;
        //   } else if (!item._ladders || !item._ladders.length) {
        //     err = "条件" + (a + 1) + "还没有设置促销明细";
        //     break;
        //   } else {
        //     if (form.proType === "group") {
        //       _rulesGoods = _rulesGoods.concat(item.rulesGoods);
        //     } else if (form.proType === "category") {
        //       for (let i = 0, l = item._categories.length; i < l; i++) {
        //         _rulesGoods.push({
        //           type: "category",
        //           targetId: item._categories[i],
        //           name: item._categoryNames[i],
        //           displayNo: i + 1,
        //           groupId: item.groupId,
        //         });
        //       }
        //     }
        //     for (let b = 0, bl = item._ladders.length; b < bl; b++) {
        //       let rule = item._ladders[b];
        //       let nr = JSON.parse(JSON.stringify(rule));
        //       delete nr.giftRules;
        //       _rules.push(nr);
        //       if (form.quantityLadder && form.method === "gift") {
        //         if (rule.giftRules && rule.giftRules.length) {
        //           for (let c = 0, cl = rule.giftRules.length; c < cl; c++) {
        //             let giftRule = rule.giftRules[c];
        //             if (giftRule.goodsList && giftRule.goodsList.length) {
        //               _giftRules.push(giftRule);
        //             } else {
        //               err = "条件" + (a + 1) + "的促销明细还没有选择赠品";
        //               break;
        //             }
        //           }
        //           if (err) break;
        //         } else {
        //           err = "条件" + (a + 1) + "的促销明细还没有选择赠品";
        //           break;
        //         }
        //       }
        //     }

        //     if (form.method === "gift" && !form.quantityLadder && a === 0) {
        //       if (item.giftRules && item.giftRules.length) {
        //         for (let c = 0, cl = item.giftRules.length; c < cl; c++) {
        //           let giftRule = item.giftRules[c];
        //           giftRule.ladderId = item._ladders[0].ladderId;
        //           if (giftRule.goodsList && giftRule.goodsList.length) {
        //             _giftRules.push(giftRule);
        //           } else {
        //             err = "还没有选择赠品";
        //             break;
        //           }
        //         }
        //         if (err) break;
        //       } else {
        //         err = "还没有选择赠品";
        //         break;
        //       }
        //     }
        //   }
        // }

        // if (err) {
        //   this.$alert(err, "系统提示", { type: "warning" });
        //   this.currentTab = "detail";
        //   form = null;
        // } else {
        //   form.rules = _rules;
        //   form.rulesGoods = _rulesGoods;
        //   form.giftRules = _giftRules;
        //   delete form._items;
        // }
      } else {
        this.$message.error("请至少设置一个活动项目");
        this.currentTab = "detail";
      }
      return form;
    },

    doSave(callback) {
      this.$refs.form
        .validate()
        .then((_) => {
          let form = this.convertForm();
          // debugger;
          // return false;
          if (form) {
            this.saving = true;
            (this.form.id ? promotion.edit : promotion.add)(form)
              .then((res) => {
                this.$emit("update:isCopy", false);

                if (typeof callback === "function") callback();
                else {
                  this.$parent.init();
                  if (res && res.id) {
                    this.$emit("update:id", res.id);
                    this.$nextTick((_) => {
                      this.resetForm();
                    });
                  }
                  this.$notify({
                    title: "保存商品促销活动成功",
                    type: "success",
                    duration: 2500,
                  });
                }
              })
              .finally((_) => {
                this.saving = false;
              });
          }
        })
        .catch((e) => {
          console.log(e);
          this.currentTab = "base";
        });
    },
    doStart() {
      let _exec = (_) => {
        this.operaing = true;
        promotion
          .start(this.form.id)
          .then((res) => {
            this.form.status = "start";
            this.$notify({
              title: "启用成功",
              type: "success",
              duration: 2500,
            });
            this.$parent.init();
          })
          .finally((_) => {
            this.operaing = false;
          });
      };
      if (this.form.status === "init") {
        this.$confirm(
          "启用商品促销活动后，不能再对该活动内容进行编辑。<br />您确定要启用该商品促销活动吗？",
          "操作确认",
          { type: "warning", dangerouslyUseHTMLString: true }
        ).then((_) => {
          this.doSave(_exec);
        });
      } else if (this.form.status === "stop") {
        this.$confirm("您确定要启用该商品促销活动吗？", "操作确认", {
          type: "warning",
        }).then(_exec);
      }
    },
    doStop() {
      if (this.form.status === "start") {
        this.$confirm("您确定要停用该商品促销活动吗？", "操作确认", {
          type: "warning",
        }).then((_) => {
          this.operaing = true;
          promotion
            .stop(this.form.id)
            .then((res) => {
              this.form.status = "stop";
              this.$notify({
                title: "停用成功",
                type: "success",
                duration: 2500,
              });
              this.$parent.init();
            })
            .finally((_) => {
              this.operaing = false;
            });
        });
      }
    },
    resetForm() {
      this.maxSubIndex = 0;
      // this.maxGroupIndex = 0;
      // this.maxLadderIndex = 0;
      if (this.id) {
        this.loading = true;
        promotion
          .get(this.id)
          .then((res) => {
            let editable = this.isCopy || (res && res.status === "init");
            (res.sublist || []).forEach((sub) => {
              sub._id = this.$uuid();
              sub._maxGroupIndex = 0;
              sub._maxGiftRuleIndex = 0;
              let itemArr = [],
                itemObj = {};
              (sub.rules || []).forEach((ladder) => {
                ladder._id = this.$uuid();
                ladder._maxGiftRuleIndex = 0;
                let item = itemObj[ladder.groupId];
                if (!item) {
                  item = {
                    _id: this.$uuid(),
                    groupId: ladder.groupId,
                    displayNo: itemArr.length + 1,
                    _maxLadderIndex: 0,
                    _ladders: [],
                  };
                  this.maxGroupIndex++;

                  itemObj[ladder.groupId] = item;
                  itemArr.push(item);
                }

                let maxInx = 0;
                ladder.targets = sub.rulesGoods.filter((t) => {
                  if (
                    t.groupId === ladder.groupId &&
                    t.ladderId === ladder.ladderId
                  ) {
                    t._id = this.$uuid();
                    if (t.displayNo > maxInx) maxInx = t.displayNo;
                    return true;
                  }
                  return false;
                });
                if (editable)
                  ladder.targets.push({ id: this.$uuid(), targetId: null });

                item._maxTargetIndex = maxInx;

                if (res.method === "gift" && res.quantityLadder) {
                  ladder.giftRules = sub.giftRules.filter((gr) => {
                    if (
                      gr.groupId === ladder.groupId &&
                      gr.ladderId === ladder.ladderId
                    ) {
                      gr._id = this.$uuid();
                      gr._maxGiftIndex = 0;
                      gr.goodsList.forEach((g) => {
                        g._id = this.$uuid();
                        if (g.displayNo > gr._maxGiftIndex)
                          gr._maxGiftIndex = g.displayNo;
                      });
                      if (gr.displayNo > ladder._maxGiftRuleIndex)
                        ladder._maxGiftRuleIndex = gr.displayNo;
                      return true;
                    }
                    return false;
                  });
                }

                item._ladders.push(ladder);
                if (ladder.displayNo > item._maxLadderIndex)
                  item._maxLadderIndex = ladder.displayNo;
              });

              if (res.method === "gift" && !res.quantityLadder) {
                (sub.giftRules || []).forEach((r) => {
                  if (r.displayNo > sub._maxGiftRuleIndex)
                    sub._maxGiftRuleIndex = r.displayNo;
                });
              } else {
                sub.giftRules = [];
              }

              sub._items = itemArr;
              delete sub.rules;
              delete sub.rulesGoods;
            });
            if (res.target) {
              if (res.targetType === "distributorArea") {
                res.target = (res.target || "").split(",");
              } else {
                res.target = (res.target || "").split(",").map(Number);
              }
            } else {
              res.target = [];
            }
            res._subs = res.sublist;
            if (!res.fileId) res.fileId = this.$uuid();
            if (this.isCopy) {
              res.name += "【复制】";
              res.id = null;
              res.status = "init";
              res.fileId = this.$uuid();
              res._subs.forEach((sub) => {
                sub.fileId = res.fileId;
                sub.id = null;
                sub.parentId = null;

                (sub._items || []).forEach((item) => {
                  item.groupId = this.$uuid();
                  (item._ladders || []).forEach((ladder) => {
                    ladder.id = null;
                    ladder.promotionId = null;
                    ladder.ladderId = this.$uuid();
                    ladder.groupId = item.groupId;
                    (ladder.targets || []).forEach((t) => {
                      t.id = null;
                      t.promotionId = null;
                      t.groupId = ladder.groupId;
                      t.ladderId = ladder.ladderId;
                    });
                    if (res.method === "gift" && res.quantityLadder) {
                      (ladder.giftRules || []).forEach((gr) => {
                        gr.id = null;
                        gr.promotionId = null;
                        gr.groupId = ladder.groupId;
                        gr.ladderId = ladder.ladderId;
                        (gr.goodsList || []).forEach((g) => {
                          g.id = null;
                          g.giftRuleId = null;
                          g.promotionId = null;
                        });
                      });
                    }
                  });
                });

                if (res.method === "gift" && !res.quantityLadder) {
                  let item = sub._items[0]._ladders[0];
                  (sub.giftRules || []).forEach((gr) => {
                    gr.id = null;
                    gr.promotionId = null;
                    gr.groupId = item.groupId;
                    gr.ladderId = item.ladderId;
                    (gr.goodsList || []).forEach((g) => {
                      g.id = null;
                      g.giftRuleId = null;
                      g.promotionId = null;
                    });
                  });
                }
              });
            }
            delete res.sublist;
            // res.activeDateRange = [res.beginTime, res.endTime];
            this.form = res;
          })
          .finally((_) => {
            this.loading = false;
          });
      } else {
        this.form = {
          name: "",
          beginTime: null,
          endTime: null,
          enabled: true,
          method: "discount", //促销方式
          proType: "group", //促销类型
          info: "",
          fileId: this.$uuid(),
          proCondition: false, //促销条件
          threshold: true, //活动门槛计算依据
          categoryPro: null, //与分类促关系
          mutuallyAmount: false, //针对金额促销
          mutuallyGveaway: false, //针对买赠促销
          quantityLadder: false, //阶梯
          giftAddUp: false,
          mutex: true,
          maxTimes: 0, //每客户启用最大优惠次数
          allMaxTimes: 0, //活动总优惠次数
          targetType: "all", //all  distributor distributorType
          target: [],
          status: "init",
          _subs: [],
          maxGiftRuleIndex: 0,
          // giftRules: [],
        };
      }
    },
  },
  mounted() {
    getCategory().then((res) => {
      this.category.store = res.content;
    });
    this.resetForm();
  },
};
</script>

<style lang="less">
.app-marketing-promotion-editor {
  height: 100%;

  table {
    width: 100%;
    border: solid #ebeef5;
    border-width: 1px 0 0 1px;
    table-layout: fixed;

    thead {
      th {
        color: #909399;
        font-weight: bold;
        border: solid #ebeef5;
        border-width: 0 1px 1px 0;
        padding: 12px 10px;
        font-size: 13px;
      }
    }

    td {
      border: solid #ebeef5;
      border-width: 0 1px 1px 0;
      padding: 12px 10px;
      font-size: 13px;
    }
  }
}
</style>